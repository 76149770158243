<template>
    <div class="config">
        <div>我的订单</div>
        <div style="height: 95%;">
            <div class="tabBox">
                <div v-for="(item,index) in tabList" :key="index"  class="tabBoxText" :class="{tab_bar_item_btna:count == index}" @click="TabbarClick(index,item)">
                    {{item.text}}
                </div>
            </div>
            <div class="content-box"
                 v-infinite-scroll="loadMore"
                 infinite-scroll-disabled="busy"
                 immediate-check="false"
                 infinite-scroll-distance="10">
                <div v-if="list.length >0 ">
                    <div v-for="(item,index) in list" :key="index" class="listbox">
                        <div class="flex flex-child-center flex-justify-content-between listbox-line">
                            <div class="listbox-line-time">
                                <div style="margin-right: 0.3rem;">{{item.createtime}}</div>
                                <div>订单号：{{item.order_num}}</div>
                            </div>
                            <div class="listbox-line-text">{{ item.pay_status_text }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.status_text }}</div>
                        </div>
                        <div class="flex flex-child-end">
                            <div style="width: 75%">
                                <div class="content-main-box" v-for="(item1,index1) in item.goods_list">
                                    <div class="content-main-box-image">
                                        <img v-if="item1.goods_image" :src="item1.goods_image">
                                        <img v-else src="../assets/images/moreImage.png">
                                    </div>
                                    <div>
                                        <div class="content-main-box-text">
                                            {{item1.goods_name}}
                                        </div>
                                        <div  class="content-main-box-spec">
                                            {{item1.goods_spec}}
                                        </div>
                                        <div class="content-main-box-price">
                                            <span >￥{{item1.price}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 25%;padding-right: 0.3rem;" class="flex flex-justify-content-end">
                                <div>
                                    <div style="font-size: 0.14rem;font-weight: 400;color: #393E4C;text-align: right;"><span >合计实付</span><span style="font-size:0.18rem;font-weight: normal;">￥{{item.order_price}}</span></div>
                                    <div class="flex" >
                                        <div class="buttonBox" @click="details(item)">订单详情</div>
                                        <div class="buttonBox" @click="cancel(item)" v-if="item.status == 0 && item.pay_status == 0">取消订单</div>
<!--                                        <div class="buttonBox" v-if="item.status == 0 || item.status == 1 || item.status == 2">修改地址</div>-->
                                        <div class="buttonBox" @click="pay(item)" style="border-color: #4B83F0;color: #4B83F0;" v-if="item.pay_status == 0">付款</div>
                                        <div class="buttonBox" style="border-color: #E10021;color: #E10021;margin-right: 0;" v-if="item.status == 3"  @click="confirm(item)">确认收货</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div  v-else style="margin-top: 10%">
                    <el-empty  :image="require('../assets/images/null.png')"  description="暂无订单"></el-empty>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";

export default {
    name: "order",
    data() {
        return {
            tabList:[
                {
                    text:'全部',
                    id:10
                },
                {
                    text:'待支付',
                    id:0
                },
                {
                    text:'已支付',
                    id:1
                },
                {
                    text:'待发货',
                    id:2
                },
                {
                    text:'待收货',
                    id:3
                },
                {
                    text:'已收货',
                    id:4
                },
            ],
            count:0,
            list:[],
            busy: false,
            page: 0,
            total_count: 0,
            params: {
                current: 1,
                limit:10
            },
            status:'',
            form:{}
        }
    },
    mounted() {
        this.onList();
    },
    methods: {
        /**
         * 确认收货
         * */
        confirm(item) {
            this.$confirm('请确认您已收到货？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post('api/kuerp/order/index/receiving', this.$qs.stringify({
                    order_num:item.order_num
                })).then(res => {
                    this.page = 0
                    this.list = []
                    this.onList();
                })
            }).catch(() => {
                // this.$message({
                //     type    : 'info',
                //     message : '已取消删除'
                // });
            });
        },
        pay(item) {
            let a = {
                pay_type:item.pay_type,
                data:item,
                totalPrice:item.order_price
            }
            this.$store.commit('setPay', a)
            this.$router.push('/pay');
        },
        /**
         * 订单详情
         * */
        details(item) {
            this.$router.push({
                name: 'orderDetails',
                query: item
            })
        },
        /**
         * 取消订单
         * */
        cancel(item) {
            this.$confirm('确认取消该订单？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post('api/kuerp/order/index/cancelOrder', this.$qs.stringify({
                    order_num:item.order_num
                })).then(res => {
                    this.page = 0
                    this.list = []
                    this.onList();
                })
            }).catch(() => {
                // this.$message({
                //     type    : 'info',
                //     message : '已取消删除'
                // });
            });
        },
        loadMore() {
            this.busy = true
            setTimeout(() => {
                this.page++
                this.onList(true)
            }, 500)
        },
        onList(flag) {
            if(this.status == 0 || this.status == 1) {
                this.form.pay_status = this.status
                this.form.status = ''
            } else  {
                this.form.status = this.status
                this.form.pay_status = ''
            }
            if(this.count == 0) {
                this.form.status = ''
                this.form.pay_status = ''
            }
            this.form.page = this.page
            this.form.limit = 10
            this.$axios.post('api/kuerp/order/index/getOrderList', this.$qs.stringify(
                this.form
            )).then(res => {
                if(res.data.code == 200) {
                    if(flag) {
                        this.list = this.list.concat(res.data.data.list)
                        if(res.data.data.count == 0) {
                            this.busy = true
                        } else {
                            this.busy = false
                        }
                    } else {
                        this.list = res.data.data.list
                        this.busy = false
                    }
                }
            })
        },
        /**
         * tab点击
         * */
        TabbarClick(index,item) {
            console.log(item,index)
            this.count = index
            if(this.count == 0) {
                this.status = ''
            } else {
                this.status = item.id
            }
            this.page = 1
            this.list = []
            this.onList()
            console.log('this.status',this.status)
        },
    },
}
</script>

<style lang="scss" scoped>
.buttonBox {
    width: 0.78rem;
    height: 0.26rem;
    border-radius: 0.16rem;
    border: 0.01rem solid #E1E1E1;
    font-size: 0.12rem;
    font-weight: 400;
    color: #2F2F2F;
    line-height: 0.26rem;
    text-align: center;
    margin-bottom: 0.2rem;
    margin-top: 0.1rem;
    margin-right: 0.05rem;
    cursor: pointer;
}
.content-main-box {
    padding: 0.11rem 0.15rem;
    display: flex;
    border-bottom: 0.01rem solid #F2F2F2;
    border-right: 0.01rem solid #F2F2F2;
    .content-main-box-image {
        img {
            width: 0.6rem;
            height: 0.6rem;
            margin-right: 0.1rem;
        }
    }
    .content-main-box-text {
        width: 100%;
        font-size: 0.16rem;
        font-weight: 500;
        color: #393E4C;
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;
    }
    .content-main-box-spec {
        font-size: 0.12rem;
        font-weight: 400;
        color: #999999;
        line-height: 0.12rem;
        margin-bottom: 0.06rem;
    }
    .content-main-box-price {
        font-size: 0.18rem;
        font-weight: bold;
        color: #333333;
        span {
            display: block;
            width: 1rem;
        }
    }
}
.listbox {
    box-shadow: 0px 0.04rem 0.1rem 0.02rem rgba(179,175,175,0.25);
    border-radius: 0.04rem;
    font-size: 0.12rem;
    font-weight: 500;
    margin-bottom: 0.15rem;
    .listbox-line {
        margin-bottom: 0.1rem;
        padding: 0.1rem 0.15rem;
        border-bottom: 0.01rem solid #F2F2F2;
        background: #F7F7F7;
        border-radius: 0.02rem;
        .listbox-line-text {
            font-size: 0.14rem;
            font-weight: 400;
            color: #4B83F0;
        }
        .listbox-line-time {
            font-size: 0.12rem;
            font-weight: 400;
            color: #999999;
            display: flex;
            align-items: center;
        }
        img {
            width: 0.12rem;
            height: 0.12rem;
        }

        .listbox-line-click {
            font-weight: 400;
            color: #4B83F0;
        }
        span {
            display: block;
            width: 0.48rem;
            text-align: right;
            font-size: 0.12rem;
            font-weight: 400;
            color: #666666;
            margin-right: 0.1rem;
        }
    }
}
.content-box {
    height: 86%;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 0.1rem;
}
.config {
    height: 100%;
    padding: 0.24rem 0.34rem;
    >div:nth-child(1) {
        font-size: 0.18rem;
        font-weight: 400;
        color: #333333;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #E4E4E4;
    }
    .tabBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tabBoxText {
            width: 16.5%;
            text-align: center;
            padding: 0.2rem 0;
            font-size: 0.12rem;
            font-weight: 400;
            color: #AAAEB7;
            background: #FFFFFF;
            cursor: pointer;
        }
        .tab_bar_item_btna {
            background: #F7F7F7;
            border-radius: 0.01rem;
            font-size: 0.12rem;
            font-weight: 500;
            color: #2F2F2F;
            border-bottom: 0.02rem solid #4B83F0;
        }
    }
}
</style>
